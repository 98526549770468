import { PlatformType } from '../../../../../../../domain';
import { ApiRequest } from '../../../../../client';
import { base } from '../../../../base';

type Params = {
  status: 'sale' | 'sold_out' | 'suspended';
}

export const update = (
  brandId: string,
  platformType: PlatformType,
  itemId: string,
  data: Params
): ApiRequest<Response> =>
  ({ ...base(), method: 'PATCH', url: `/brands/${brandId}/order-platforms/${platformType}/items/${itemId}`, data });
