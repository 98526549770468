import { PlatformType } from '../../../../../../../domain';
import { ApiRequest } from '../../../../../client';
import { base } from '../../../../base';

type Params =
  | {
    status: 'ACCEPTED'
    cookingTime: number | null;
  }
  | { status: 'READY' | 'COMPLETED' | 'CANCELED' };

export const update = (
  brandId: string,
  platformType: PlatformType,
  orderId: string,
  data: Params
): ApiRequest<Response> =>
  ({ ...base(), method: 'PATCH', url: `/brands/${brandId}/order-platforms/${platformType}/orders/${orderId}`, data });
