import { useEffect } from 'react';
import { hasLocalizedDescription, isLocalizableRfc7807Compliant, UnknownError } from '../../../domain';
import { alert, logger } from '../../../infrastructure';
import { isHttpError } from '../../../infrastructure/apis/core';
import { pickObject } from '../../../utils';

export const useErrorHandler = (error: Error | undefined): void => {
  useEffect(() => {
    if (!error) return;

    logger.error(error);

    const { localizedTitle, localizedDetail } = mapError(error);
    alert(localizedTitle, localizedDetail, { text: 'OK' }, { cancelable: false });

  }, [error]);
};

const mapError = (error: Error) => {
  if (isHttpError(error) && isLocalizableRfc7807Compliant(error.response.data))
    return pickObject(error.response.data, ['localizedTitle', 'localizedDetail']);

  return hasLocalizedDescription(error) ? error : new UnknownError();
};
