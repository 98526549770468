import { ApiRequest } from 'infrastructure';
import { useEffect, useState } from 'react';
import { createApiClient } from '../../../infrastructure';
import { useAuthState } from './useAuthState';

type Response<T> = [T | undefined, boolean, Error | undefined];

export const useResourceHttpApi = <T>(request: ApiRequest<T> | undefined): Response<T> => {
  const [auth, authLoading] = useAuthState();

  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!auth || authLoading || loading || data) return;

    if (!request) return;

    setLoading(true);

    auth.getIdToken()
      .then((token: string) => ({
        ...request,
        headers: { ...request.headers, Authorization: `Bearer ${token}` }
      }) as ApiRequest<T>)
      .then(createApiClient().request)
      .then((data: T) => { setData(data); })
      .catch((error: Error) => { setError(error); })
      .finally(() => { setLoading(false); });
  }, [request, auth, authLoading, data, loading]);

  return [data, loading, error];
};
