import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { DeliveryMethod } from '../../domain';
import { OrderlyAppApi } from '../../infrastructure';
import { AppText, Clickable, Radio, WithModal } from '../components';
import { Color, Route } from '../constants';
import { useMutationHttpApi } from '../hooks';

export const DeliverySettingScreen = (
  { navigation, route }: StackScreenProps<Route, 'DeliverySetting'>
): React.ReactElement => {
  const { platform } = route.params;
  const [request, loading, error] = useMutationHttpApi();

  const [selected, setSelected] = useState(platform.deliveryMethod ?? DeliveryMethod.PLATFORM);

  const onPressChange = useCallback(() =>
    request(OrderlyAppApi.brands.orderPlatforms.settings.update(
      platform.brandId,
      platform.type,
      { deliveryMethod: selected }
    ))
      .then(() => { navigation.goBack(); })
  , [request, selected, navigation, platform]);

  const onPressPlatform = useCallback(() => setSelected(DeliveryMethod.PLATFORM), []);
  const onPressInhouse = useCallback(() => setSelected(DeliveryMethod.INHOUSE), []);
  const onPressFallback = useCallback(() => setSelected(DeliveryMethod.FALLBACK), []);

  const Header = useMemo(() =>
    <>
      <AppText style={styles.title}>配達設定を選択してください</AppText>
      <View style={styles.border} />
    </>
  , []);

  const Body = useMemo(() =>
    <View style={styles.selectorView}>
      <Radio
        text="Uber Eatsの配達員のみを利用する"
        selected={selected === DeliveryMethod.PLATFORM}
        size='large'
        outline
        onPress={onPressPlatform} />
      <Radio
        text={'店舗スタッフが配達する\n（配達エリア内の注文のみに対応）'}
        selected={selected === DeliveryMethod.INHOUSE}
        size='large'
        outline
        onPress={onPressInhouse} />
      <Radio
        text={'店舗スタッフとUber Eatsの配達員を併用する\n（配達エリア内は店舗スタッフ、エリア外は\nUber Eatsの配達員が対応）'}
        selected={selected === DeliveryMethod.FALLBACK}
        size='large'
        outline
        onPress={onPressFallback} />
      <Clickable name="close_modal" onPress={onPressChange} style={styles.changeButton}>
        <AppText style={styles.changeButtonText}>変更</AppText>
      </Clickable>
    </View>
  , [onPressChange, onPressFallback, onPressInhouse, onPressPlatform, selected]);

  return <WithModal {...{ Header, Body, loading, error }} />;
};

const styles = StyleSheet.create({
  view: {
    width: 500,
    height: 552,
    justifyContent: 'center',
  },
  title: {
    height: 70,
    paddingHorizontal: 40,
    fontSize: 18,
    lineHeight: 70,
    textAlign: 'center'
  },
  border: {
    height: 2,
    width: '100%',
    backgroundColor: Color.gray10,
  },
  selectorView: {
    padding: 40,
  },
  changeButton: {
    width: 296,
    height: 56,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 28,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#372E61',
    borderRadius: 4
  },
  changeButtonText: {
    color: Color.white,
    fontSize: 18,
  }
});
