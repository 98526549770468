import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { OrderlyAppApi } from '../../../infrastructure';
import { AppText, PrimaryButton, Radio, WithModal } from '../../components';
import { Color, Route } from '../../constants';
import { useAppContext, useMutationHttpApi, useOrderPlatformSuspensions, usePeriodicUpdater } from '../../hooks';

const operationTypes = {
  delete: 'すべてのプラットフォームを受付中に変更' as const,
  create: 'すべてのプラットフォームを停止中に変更' as const
};

export const BulkUpdateOrderPlatformSuspensionsScreen = (
  { route, navigation }: StackScreenProps<Route, 'BulkUpdateOrderPlatformSuspensions'>
): React.ReactElement => {

  const { brandId } = route.params;
  const { store, platforms } = useAppContext();
  const [request, loading, error] = useMutationHttpApi();

  const { now } = usePeriodicUpdater(120 * 1000);

  const [operationType, setOperationType] = useState<keyof typeof operationTypes>('delete');

  const [orderPlatformSuspensions, loadingOrderPlatformSuspensions, errorOrderPlatformSuspension] =
    useOrderPlatformSuspensions(store && { accountId: store.accountId, storeId: store.id, now: new Date(now) });

  const onPress = useCallback(() => {
    if (!platforms) return;

    const platformsByBrandId = platforms.filter(platform => platform.brandId === brandId);

    // NOTE: Do not include platforms that are already in the desired state.
    const targetPlatforms = platformsByBrandId
      .filter(platform => {
        const existing = orderPlatformSuspensions
          ?.some(suspension => suspension?.brandId === platform.brandId && suspension.platformType === platform.type);
        return operationType === 'delete' ? existing : !existing;
      });

    if (operationType === 'create')
      return navigation.navigate('CreateOrderPlatformSuspensions', { platforms: targetPlatforms });

    return Promise.resolve(targetPlatforms)
      .then(platforms => platforms.map(platform => request(
        OrderlyAppApi.brands.orderPlatforms.suspension.destroy(brandId, platform.type)
      )))
      .then(promises => Promise.allSettled(promises))
      .then(results => {
        const hasError = results.some(result => result.status === 'rejected');
        if (hasError) return;
        navigation.goBack();
      })
      .catch(() => Promise.resolve());
  }, [request, navigation, brandId, operationType, orderPlatformSuspensions, platforms]);

  const onPressCreateRadio = useCallback(() => {
    setOperationType('create');
  }, []);

  const onPressDeleteRadio = useCallback(() => {
    setOperationType('delete');
  }, []);

  const Header = <><AppText style={styles.title}>一括変更の操作を選択してください</AppText><View style={styles.divider}/></>;
  const Body = <View style={styles.body}>
    <View style={styles.radios}>
      <Radio
        key={`${brandId}-bulk-update-order-platfrom-suspensions-delete`}
        text={operationTypes['delete']}
        selected={operationType === 'delete'}
        size='large'
        outline
        onPress={onPressDeleteRadio}/>
      <Radio
        key={`${brandId}-bulk-update-order-platfrom-suspensions-create`}
        text={operationTypes['create']}
        selected={operationType === 'create'}
        size='large'
        outline
        onPress={onPressCreateRadio}/>
    </View>
    <View style={styles.button}>
      <PrimaryButton
        disabled={loading}
        onPress={onPress}
        title={operationType === 'create'? '次へ': '確定'}
        size='large'
      />
    </View>
  </View>;

  return <WithModal {...{
    Header,
    Body,
    loading: loading || loadingOrderPlatformSuspensions,
    error: error || errorOrderPlatformSuspension
  }} />;
};

const styles = StyleSheet.create({
  body: {
    width: 504,
    alignSelf: 'center'
  },
  divider: {
    height: 2,
    width: '100%',
    backgroundColor: Color.gray10,
  },
  title: {
    height: 70,
    paddingHorizontal: 40,
    fontSize: 18,
    lineHeight: 70,
    textAlign: 'center'
  },
  radios: {
    paddingTop: 40,
    paddingHorizontal: 40
  },
  button: {
    paddingHorizontal: 104,
    paddingTop: 24,
    paddingBottom: 40,
  }
});
