import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { OrderlyAppApi } from '../../infrastructure';
import { AppText, NavigationBar, PrimaryButton, Radio, WithModal } from '../components';
import { Color, Route } from '../constants';
import { useMutationHttpApi } from '../hooks';

export const CancelScreen = (
  { navigation, route }: StackScreenProps<Route, 'Cancel'>
): React.ReactElement => {
  const { order, onComplete } = route.params;
  const [request, loading, error] = useMutationHttpApi();

  const [checked, setChecked] = useState(false);

  const onPressBack = useCallback(() => {
    navigation.goBack();
    navigation.navigate('Support', { order, onComplete });
  }, [navigation, onComplete, order]);

  const onPressRadio = useCallback(() => {
    setChecked(checked => !checked);
  }, []);

  const onPressConfirm = useCallback(() =>
    request(OrderlyAppApi.brands.orderPlatforms.orders.update(
      order.brandId,
      order.platform,
      order.id,
      { status: 'CANCELED' }
    ))
      .then(() => {
        navigation.goBack();
        onComplete?.();
      })
      .catch(() => Promise.resolve())
  , [request, navigation, onComplete, order]);

  const Header = <NavigationBar title="注文をキャンセル" onPressBack={onPressBack} isForMenu={true} />;
  const Body = <View style={styles.body}>
    <View style={styles.top}>
      <AppText style={styles.text}>
        こちらの注文をキャンセルしてよろしいですか？ 注文のキャンセル後は取り消せません。
      </AppText>
      <View style={styles.radio}>
        <Radio text="注文をキャンセルする" selected={checked} size='large' outline onPress={onPressRadio} />
      </View>
    </View>
    <View style={styles.buttonArea}>
      <PrimaryButton size='large' title="実行する" onPress={onPressConfirm} color={Color.red} disabled={!checked}/>
    </View>
  </View>;

  return <WithModal {...{ Header, Body, loading, error }} />;
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    justifyContent: 'space-between',
    paddingVertical: 40,
    paddingHorizontal: 56
  },
  top: {
    alignItems: 'center'
  },
  text: {
    fontSize: 16,
    color: Color.gray100,
    marginBottom: 32
  },
  radio: {
    width: 424
  },
  buttonArea: {
    alignItems: 'center',
  }
});
