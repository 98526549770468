import { BusinessDayTime, PlatformType } from '../../../../../../../domain';
import { ApiRequest } from '../../../../../client';
import { base } from '../../../../base';

type Params = {
  businessDayTimes: BusinessDayTime[];
}

export const update = (brandId: string, platformType: PlatformType, data: Params): ApiRequest<void> =>
  ({ ...base(), method: 'PUT', url: `/brands/${brandId}/order-platforms/${platformType}/business-day-times`, data });
