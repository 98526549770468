import dayjs from 'dayjs';
import React, { useCallback, useEffect, useReducer } from 'react';
import { ListRenderItemInfo, StyleSheet, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import {
  BusinessDayTime,
  BusinessDayTimeReference,
  BusinessTime,
  ExtraBusinessDayTime,
  PlatformType,
} from '../../../../../../domain';
import { alert, logger, OrderlyAppApi } from '../../../../../../infrastructure';
import { groupBy, pickObject } from '../../../../../../utils';
import { Color } from '../../../../../constants';
import { decorateSectionName } from '../../../../../decorators';
import { useAppContext, useMutationHttpApi } from '../../../../../hooks';
import { AppText } from '../../../../AppText';
import { IconTextButton } from '../../../../buttons/IconTextButton';
import { OutlineButton } from '../../../../buttons/OutlineButton';
import { PrimaryButton } from '../../../../buttons/PrimaryButton';
import { Radio } from '../../../../forms/Radio';
import { If } from '../../../../If';
import { MultiDatePicker, TimeInputs } from '../elements';
import { ExtraBusinessDayTimeFormAction, extraBusinessDayTimeFormReducer } from './extraBusinessDayTimeFormReducer';

interface Props {
  brandId: string
  platformType: PlatformType
  businessDayTimes: ExtraBusinessDayTime[]

  onFinishEditing: () => void
}

type Item = [number , ExtraBusinessDayTime[]];

export const ExtraForms = (
  { brandId, businessDayTimes, platformType, onFinishEditing }: Props
): React.ReactElement  => {
  const { store, integrations } = useAppContext();
  const initialState: Item[] = groupBy(businessDayTimes, ({ businessTimes }) => generateKey(businessTimes))
    .map((businessDayTimes, sectionIndex) => [sectionIndex ,businessDayTimes[1]]);
  const [state, dispatch] = useReducer(extraBusinessDayTimeFormReducer, initialState);

  const [request, loading, error] = useMutationHttpApi();

  const renderItem = useCallback(({ item }: { item: Item }) =>
    <ExtraForm {...{
      sectionIndex: item[0],
      businessDayTimes: item[1],
      dispatch,
      supportsUnlimitedExtraTimeFrames: integrations
        ?.find(integration => integration.platformType === businessDayTimes[0]?.platformType)
        ?.features.businessDayTimes.unlimitedExtraTimeFrames
        .supported
        ?? false,
    }}/>, [businessDayTimes, integrations]);

  const keyExtractor = useCallback(([index]: Item) => `extra-forms-${index}`, []);

  const onPressSave = useCallback(() => {
    const businessDayTimes = state
      .map(businessDayTimesGroup => businessDayTimesGroup[1])
      .reduce((acc, elem) => acc.concat(elem), [])
      .map(omitTimeStamps);

    request(OrderlyAppApi.brands.orderPlatforms.businessDayTimes.update(brandId, platformType, { businessDayTimes }))
      .then(() => onFinishEditing())
      .catch(() => Promise.resolve());
  }, [brandId, platformType, request, onFinishEditing, state]);

  const onPressAdd = useCallback(() =>
    dispatch({
      type: 'addBusinessDayTimeWithNewSection',
      payload: {
        accountId: store?.accountId ?? '',
        storeId: store?.id ?? '',
        brandId,
        platformType,
        date: dayjs().format('YYYY-MM-DD')
      }
    })
  , [brandId, platformType, store]);

  useEffect(() => {
    if (!error) return;

    alert('エラーが発生しました。再度お試しください。', error?.message, { text: '閉じる', }, { cancelable: false, });
    logger.error(error);
  }, [error]);

  return <>
    <View style={styles.title}>
      <AppText style={styles.sectionName}>{decorateSectionName('extra' as const, null)}</AppText>
      <View style={styles.buttonGroup}>
        <OutlineButton disabled={loading} size='medium' title='キャンセル' onPress={onFinishEditing} />
        <View style={styles.buttonSeparator}/>
        <PrimaryButton disabled={loading} size='medium' title='保存' onPress={onPressSave} />
      </View>
    </View>
    <View style={styles.content}>
      <FlatList {...{ data: state, renderItem, keyExtractor }} />
      <View style={styles.outLineButtonWrapper}>
        <OutlineButton size='medium' title='日にち追加' onPress={onPressAdd} />
      </View>
    </View>
  </>;
};

interface ExtraFormProps {
  sectionIndex: number
  businessDayTimes: BusinessDayTime[]
  dispatch: React.Dispatch<ExtraBusinessDayTimeFormAction>
  supportsUnlimitedExtraTimeFrames: boolean
}

const ExtraForm = ({ sectionIndex, businessDayTimes, dispatch, supportsUnlimitedExtraTimeFrames }: ExtraFormProps) => {
  const renderItem = useCallback(({ item, index }: ListRenderItemInfo<BusinessTime>) =>
    <TimeInputs {...{ sectionIndex, dispatch, timeInputsIndex: index, businessTime: item }} />
  , [dispatch, sectionIndex]);

  const keyExtractor = useCallback(({ startTime, endTime }: BusinessTime) =>
    `extra-forms-business-day-time-${businessDayTimes[0]?.id}-${startTime}-${endTime}`
  , [businessDayTimes]);

  const onPressTime = useCallback(() => {
    dispatch({ type: 'switch', payload: { sectionIndex, availability: true }});
  }, [dispatch, sectionIndex]);

  const onPressHoliday = useCallback(() => {
    dispatch({ type: 'switch', payload: { sectionIndex, availability: true }});
  }, [dispatch, sectionIndex]);

  const onPressAddTimes = useCallback(() =>
    dispatch({ type: 'addTimes', payload: { sectionIndex: sectionIndex } })
  , [dispatch, sectionIndex]);

  return <View style={styles.extraForm}>
    <MultiDatePicker
      sectionIndex={sectionIndex}
      datesWithAvailability={(businessDayTimes as ExtraBusinessDayTime[])
        .map(businessDayTime => ({ date: businessDayTime.date, disabled: businessDayTime.disabled }))
      }
      dispatch={dispatch}/>
    <View style={styles.radioButtonGroup}>
      <Radio
        text={'時間指定'}
        selected={businessDayTimes[0]?.businessTimes.length !== 0}
        size='medium'
        outline={false}
        onPress={onPressTime} />
      <View style={styles.buttonSeparator}/>
      <Radio
        text={'臨時休業'}
        selected={businessDayTimes[0]?.businessTimes.length === 0}
        size='medium'
        outline={false}
        onPress={onPressHoliday} />
    </View>
    <View style={styles.timeInputss}>
      <If condition={businessDayTimes[0]?.businessTimes.length}>
        <If condition={supportsUnlimitedExtraTimeFrames}>
          <FlatList
            data={businessDayTimes[0]?.businessTimes}
            renderItem={renderItem}
            keyExtractor={keyExtractor}/>
          <IconTextButton
            title='時間追加'
            icon={require('../../../../../assets/icon/add.png')}
            onPress={onPressAddTimes}/>
        </If>
        <If condition={!supportsUnlimitedExtraTimeFrames}>
          <TimeInputs
            sectionIndex={sectionIndex}
            timeInputsIndex={0}
            businessTime={businessDayTimes[0]?.businessTimes[0] ?? { startTime: '', endTime: '' }}
            dispatch={dispatch}
          />
        </If>
      </If>
    </View>
  </View>;
};

const generateKey = (businessTimes: BusinessTime[]) =>
  businessTimes
    ?.map((businessTime) => `${businessTime.startTime}-${businessTime.endTime}`)
    .reduce((acc, cur) => acc + cur, 'extraForm-')
    .replace(/:/g, '')
    ?? null;

//Note: Prevent overwriting of persistentBusinessDayTime timestamps
const omitTimeStamps = (businessDayTime: ExtraBusinessDayTime): ExtraBusinessDayTime & BusinessDayTimeReference => ({
  ...pickObject(businessDayTime, ['id', 'accountId', 'storeId', 'brandId', 'platformType']),
  ...pickObject(businessDayTime, ['date', 'type', 'disabled', 'businessTimes'])
});

const styles = StyleSheet.create({
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row'
  },
  buttonSeparator: {
    paddingHorizontal: 8
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  extraForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    borderLeftColor: Color.gray5,
    borderLeftWidth: 4,
    borderRadius: 4,
    borderStyle: 'solid',
    paddingLeft: 12,
    paddingTop: 4
  },
  outLineButtonWrapper: {
    marginTop: 20,
    maxWidth: 100
  },
  radioButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  sectionName: {
    fontSize: 16,
    color: Color.gray100,
    marginTop: -3
  },
  timeInputss: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 2
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    height: 36,
    justifyContent: 'space-between',
  },
});
