import { PlatformType } from '../../../../../../../domain';
import { ApiRequest } from '../../../../../client';
import { base } from '../../../../base';

type Response = {
  maxPreparationMinute: number;
};

export const get = (brandId: string, platformType: PlatformType): ApiRequest<Response> =>
  ({ ...base(), method: 'GET', url: `/brands/${brandId}/order-platforms/${platformType}/configurations` });
