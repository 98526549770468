import { PlatformType, SuspensionReason } from '../../../../../../../domain';
import { ApiRequest } from '../../../../../client';
import { base } from '../../../../base';

type Params = {
  minutes: number | null;
  suspensionReason: SuspensionReason;

};

export const create = (brandId: string, platformType: PlatformType, data: Params): ApiRequest<Response> =>
  ({ ...base(), method: 'POST', url: `/brands/${brandId}/order-platforms/${platformType}/suspension`, data });
