import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { OrderlyAppApi } from '../../../infrastructure';
import { AppText, PrimaryButton, WithModal } from '../../components';
import { Route } from '../../constants';
import { useMutationHttpApi } from '../../hooks';

export const DeleteOrderPlatformSuspensionScreen = (
  { route, navigation }: StackScreenProps<Route, 'DeleteOrderPlatformSuspension'>
): React.ReactElement => {
  const { platform } = route.params;
  const [request, loading, error] = useMutationHttpApi();

  const onPress = useCallback(() =>
    request(OrderlyAppApi.brands.orderPlatforms.suspension.destroy(platform.brandId, platform.type))
      .then(() => navigation.navigate('OrderPlatformSuspensions'))
      .catch(() => Promise.resolve())
  , [request, navigation, platform]);

  const Body = <View style={styles.body}>
    <AppText style={styles.title}>新規注文の受付を再開しますか？</AppText>
    <PrimaryButton disabled={loading} onPress={onPress} title={'再開する'} size='large' />
  </View>;

  return <WithModal {...{ Body, loading, error }} />;
};

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 40,
    width: 402,
  },
  title: {
    height: 70,
    fontSize: 18,
    lineHeight: 70,
    textAlign: 'center',
    marginBottom: 4
  }
});
