import { AcceptanceMethod, DeliveryMethod, PlatformType } from '../../../../../../../domain';
import { ApiRequest } from '../../../../../client';
import { base } from '../../../../base';

type Params = {
  acceptanceMethod?: AcceptanceMethod;
  deliveryMethod?: DeliveryMethod;
  pickupPosItemEnabled?: boolean;
};

export const update = (brandId: string, platformType: PlatformType, data: Params): ApiRequest<void> =>
  ({ ...base(), method: 'PATCH', url: `/brands/${brandId}/order-platforms/${platformType}/settings`, data });
