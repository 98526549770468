import { businessDayTimes } from './businessDayTimes';
import { configurations } from './configurations';
import { items } from './items';
import { orders } from './orders';
import { settings } from './settings';
import { suspension } from './suspension';

export const orderPlatforms = {
  businessDayTimes,
  configurations,
  items,
  orders,
  settings,
  suspension
};
