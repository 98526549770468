import { PlatformType } from '../../../../../../../../domain';
import { ApiRequest } from '../../../../../../client';
import { base } from '../../../../../base';

type Params = {
  preparationTime: number; // ミリ秒
}

export const create = (
  brandId: string,
  platformType: PlatformType,
  orderId: string,
  data: Params
): ApiRequest<Response> =>
  ({ ...base(), method: 'POST', url: `/brands/${brandId}/order-platforms/${platformType}/orders/${orderId}/delay`, data });
