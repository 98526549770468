import { Environment } from '../../../Environment';
import { ApiRequest } from '../client';

export type OrderlyManagerApiRequestBase = Pick<ApiRequest<void>, 'baseURL' | 'headers' | 'apiName' | 'authenticationErrorStatusCodes'>

const host = (() => {
  switch (Environment.projectEnvironment) {
    case 'development': return 'development-app-api.orderly.jp';
    case 'staging': return 'staging-app-api.orderly.jp';
    case 'production': return 'app-api.orderly.jp';
    default: return 'development-app-api.orderly.jp';
  }
})();

export const base = (): OrderlyManagerApiRequestBase => ({
  baseURL: `https://${host}/api/app`,
  apiName: 'OrderlyAppApi',
  authenticationErrorStatusCodes: [401, 403]
});
